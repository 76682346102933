@import "minima";

h1, h2, h3 {
  font-weight: bold;
  text-align: left;
  }


img.pull-right {
	max-width: 50%;
	float: right;
	margin: 7px 5px 0 10px;
}


body {
    text-align: left;
}


a.pdf:link, a.pdf:visited {
    color: white;
    border-radius: 5px;
    padding: 0px 4px 0px 4px;
    text-align: center;
    text-decoration: none;
    font-size: 50%;
    display: inline-block;
    transform: translateY(-25%);
}

a.pdf:link, a.pdf:visited {
    background-color: #96DC8D;
}
a.pdf:hover, a.pdf:active {
    background-color: #61D16A;
}


a.code:link, a.code:visited {
    color: white;
    border-radius: 5px;
    padding: 0px 4px 0px 4px;
    text-align: center;
    text-decoration: none;
    font-size: 50%;
    display: inline-block;
    transform: translateY(-25%);
}


a.code:link, a.data:visited {
    background-color: #8DBADC;
}
a.code:hover, a.data:active {
    background-color: #61A0D1;
}


a.data:link, a.data:visited {
    color: white;
    border-radius: 5px;
    padding: 0px 4px 0px 4px;
    text-align: center;
    text-decoration: none;
    font-size: 50%;
    display: inline-block;
    transform: translateY(-25%);
}


a.data:link, a.data:visited {
    background-color: #DCCD8D;
}
a.data:hover, a.data:active {
    background-color: #D1B361;
}


ol.bibliography, table {
    text-align: left;
}


/**
 * Basic styling
 */
body {
    color: #313131;
}

ul {
    text-align: left;
    }


/**
 * Icons
 */
.icon {
    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}


img.pixel {
    image-rendering: optimizeSpeed;             /* STOP SMOOTHING, GIVE ME SPEED  */
    image-rendering: -moz-crisp-edges;          /* Firefox                        */
    image-rendering: -o-crisp-edges;            /* Opera                          */
    image-rendering: -webkit-optimize-contrast; /* Chrome (and eventually Safari) */
    image-rendering: optimize-contrast;         /* CSS3 Proposed                  */
    image-rendering: pixelated;                 /* Chrome */
    -ms-interpolation-mode: nearest-neighbor;   /* IE8+  */
}

/**
 * Tables
 */
table {
  margin-bottom: 0;
  width: 100%;
  text-align: left;
  color: $text-color;
  border-collapse: collapse;
  border: 0px;
  tr {
    &:nth-child(even) {
      background-color: white;
    }
  }
  th, td {
    padding: 0px 2px;
  }
  th {
    background-color: white;
    border: 0px;
    border-bottom-color: white;
  }
  td {
    border: 0px;
  }
}


article.page {
    // for displaying images nicely next to each other
    table {
       max-width: calc(30% + 300px);
       max-width: -webkit-calc(30% + 300px);
      }
    td {
        vertical-align: text-top;
     }
}


article.post {
    // for displaying images nicely next to each other
    table {
       table-layout: fixed;
       width: 100%;
      }
    td {
        vertical-align: text-top;
     }
    // so that video and image are shown with same width
    video, img {
        vertical-align: middle;
    }
}


/**
 * Layout
 */
.site-header {
  border-top: 3px solid $grey-color-light;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;
}

.post-link {
    font-size: 16px;
    color: rgb(42, 122, 226);
}

.post-link:hover {
    text-decoration: none;
}

